import React from "react";

import NewsArticle from "../../../components/news-article/news-article.component";

import { LATEST_RATE_SHEET } from "../../../data/latest-rate-sheet.data";

const Article = () => (
  <NewsArticle>
    <h1 className="page-title">Changes to our mortgage products</h1>
    <p>With effect from Tuesday 11 October 2022 we will be making the following changes to our range:</p>
    <p>
      <strong>Summary of changes</strong>
    </p>
    <p>
      <strong>UK Buy To Let Existing Customer Switching & Borrowing More Range</strong>
    </p>
    <ul>
      <li>5 Year Fixed Standard at 60%, 65% and 75% LTV have increased</li>
    </ul>
    <p>
      We have reintroduced our UK Buy to Let proposition for Purchase, Remortgage and Existing Customers Switching and
      Borrowing More. Our Two Year Fixed Rate range is only available for Existing Customers Switching.
    </p>
    <p>There are no other changes to any other rates or to fixed rate end dates.</p>
    <p>An updated mortgage rate sheet will be published on Tuesday 11 October to reflect these changes.</p>
    <p>
      <a title="This PDF opens in a new window" target="_blank" href={LATEST_RATE_SHEET.href}>
        Link to our latest rate sheet
      </a>
    </p>
  </NewsArticle>
);

export default Article;
